import React, { useContext, useEffect, useState } from "react";
import { SEO } from "../../components";
import { useApolloClient } from "@apollo/client";
import {
  GET_ALL_FORESTS_AND_TREES,
  GET_FOREST_DETAILS_BY_ID,
  GET_FOREST_SEO_BY_UNIQUE_ID,
  GET_TREE_SEO_BY_FOREST_ID,
  GET_TREES_BY_NODEID,
} from "../../graphql/queries";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import styled from "@mui/material/styles/styled";
import {
  ContributionDetails,
  ContributionCertificateScreen,
  Contribution,
} from "../../screens";
import { Routes } from "../../router/routes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { InfoDetails } from "../../components/map/infoDetails";
import { DialogContext } from "../../contexts";
import TreeInfo from "../../components/map/treeInfo";
import CustomForestMap from "../../components/customForestMap/customForestMap";

const ContributionPopUp = styled(Popover)({});

export const Home = (props) => {
  const client = useApolloClient();
  const dialog = useContext(DialogContext);

  const [state, setState] = useState({
    allForestDetails: [],
    forestList: [],
    initialCenter: "",
  });

  const history = useHistory();
  let path = useParams();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [letsPlant, setLetsPlant] = React.useState(false);
  const [proceedPay, setProceedPay] = React.useState(false);
  const [timelineOrder, setTimelineOrder] = useState({ label: "", value: '' });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [seoData, setSeoData] = useState({
    title: "Be The Tree Hugger",
    description:
      "Be The Tree Hugger, is your go-to hub for eco-conscious living. Discover practical tips, inspiring content, and a community dedicated to preserving our planet. Join us in our mission to cultivate a greener, more sustainable world.",
    type: "website",
    imageUrl: `${window.origin}/images/bth_og_mirchi.jpg`,
  });

  const location = useLocation();

  useEffect(() => {
    getAllForests();
    getSeoDetails();
    // eslint-disable-next-line
  }, []);

  const getAllForests = async () => {
    await client
      .query({
        query: GET_ALL_FORESTS_AND_TREES,
      })
      .then((res) => {
        const tempList = res?.data?.allForests?.nodes;

        let finalList = tempList?.map?.(forest => {
          let finalClusters = [];

          if (forest?.forestBoxesByForestId?.nodes?.length > 0) {
            finalClusters = forest?.forestBoxesByForestId?.nodes?.map?.(box => {
              return {
                id: box?.id,
                nodeId: box?.nodeId,
                boxUniqueId: box?.uniqueId,
                boundaries: JSON.parse(box?.boxBoundary),
                treesCount: box?.forestTreesByBoxId?.totalCount,
                clusterTrees: box?.forestTreesByBoxId?.nodes?.map?.(tree => {
                  return {
                    id: tree?.id,
                    nodeId: tree?.nodeId,
                    treeUniqueId: tree?.treeUniqueId,
                    forestTreeName: tree?.forestTreeName,
                    plantSpecies: {
                      id: tree?.masterPlantspecyByMasterPlantSpeciesId?.id,
                      speciesName: tree?.masterPlantspecyByMasterPlantSpeciesId?.speciesName,
                      commonName: tree?.masterPlantspecyByMasterPlantSpeciesId?.commonName,
                    }
                  }
                }),
              }
            })
          } else if (forest?.forestTreesByForestId?.nodes?.length > 0) {
            const id = crypto?.randomUUID();
            const boxUniqueId = crypto?.randomUUID();
            let nodeId = btoa(JSON?.stringify(["forest_box", id]));
            let clusterTrees = [];

            if (forest?.forestTreesByForestId?.nodes?.length > 0) {
              clusterTrees = forest?.forestTreesByForestId?.nodes?.map?.(tree => {
                return {
                  id: tree?.id,
                  nodeId: tree?.nodeId,
                  treeUniqueId: tree?.treeUniqueId,
                  forestTreeName: tree?.forestTreeName,
                  plantSpecies: {
                    id: tree?.masterPlantspecyByMasterPlantSpeciesId?.id,
                    speciesName: tree?.masterPlantspecyByMasterPlantSpeciesId?.speciesName,
                    commonName: tree?.masterPlantspecyByMasterPlantSpeciesId?.commonName,
                  }
                }
              })
            }

            finalClusters = [{
              id,
              nodeId: nodeId,
              boxUniqueId,
              boundaries: JSON.parse(forest?.forestBoundary),
              treesCount: forest?.forestTreesByForestId?.totalCount,
              clusterTrees,
            }]
          }

          return {
            id: forest?.id,
            nodeId: forest?.nodeId,
            forestUniqueId: forest?.forestUniqueId,
            forestName: forest?.forestName,
            forestLogo: forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorForestLogo,
            center: {
              lat: Number(forest?.forestGeoLat),
              lng: Number(forest?.forestGeoLong),
            },
            boundaries: JSON.parse(forest?.forestBoundary),
            clusters: finalClusters
          }
        })

        props?.onChangeSearch({
          label: finalList?.[0]?.forestName,
          value: finalList?.[0]?.center,
        });

        setState({
          allForestDetails: tempList,
          forestList: finalList,
          initialCenter: finalList?.[0]?.center,
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeoDetails = () => {
    let temp = location.pathname.split("/").filter(Boolean);
    let type, unique_id, foerst_unique_id;
    if (location.pathname.includes("forest")) {
      type = temp[0];
      unique_id = temp[1];
    } else {
      type = temp[0];
      foerst_unique_id = temp[1];
      unique_id = temp[2];
    }
    if (!type || !unique_id || !foerst_unique_id) return;
    let query = GET_FOREST_SEO_BY_UNIQUE_ID;
    if (type === "tree") {
      query = GET_TREE_SEO_BY_FOREST_ID;
    }
    client
      .query({
        query,
        variables: {
          unique_id,
          foerst_unique_id,
        },
      })
      .then(({ data }) => {
        const getTitle = ({ name, forest, sponsors }) => {
          if (type !== "tree") {
            return `${name}, sponsored by ${sponsors?.nodes
              .map((i) => i.sponsorBySponsorId.sponsorName)
              .join(",")}`;
          }
          return `${name} tree planted in ${forest?.name
            }, sponsored by ${sponsors?.nodes
              .map((i) => i?.sponsorBySponsorId?.sponsorName)
              .join(",")}`;
        };
        const getDescription = ({
          total_trees,
          total_species_planted,
          oxygen_produced,
          carbon_offset,
          species,
        }) => {
          if (type !== "tree") {
            return `Rich treasure with ${total_trees} trees, ${total_species_planted} species, generated ${Number(oxygen_produced) / 1000
              } kt of oxygen and has offset ${Number(carbon_offset) / 1000
              } kt of carbon.`;
          }
          return `Belonging to ${species?.name} species, generated ${Number(oxygen_produced) / 1000
            } kt of oxygen and has offset ${Number(carbon_offset) / 1000
            } kt of carbon.`;
        };
        let seoData = data.seoData;
        if (type === "tree") {
          seoData = data?.seoData?.nodes[0];
        }
        let {
          name,
          forest,
          total_trees,
          total_species_planted,
          oxygen_produced,
          carbon_offset,
          sponsors,
          species,
        } = seoData;
        setSeoData({
          ...seoData,
          title: getTitle({ name, forest, sponsors }),
          description: getDescription({
            total_trees,
            total_species_planted,
            oxygen_produced,
            carbon_offset,
            species,
          }),
          imageUrl: sponsors?.nodes?.length
            ? sponsors.nodes?.[0]?.sponsorBySponsorId?.sponsorOgImageUrl
            : `${window.origin}/images/bth_og_mirchi.jpg`,
        });
      })
      .catch(console.error);
  };

  const letsPlantFunc = () => {
    setAnchorEl(null);
    setLetsPlant(true);
  };

  const HandlePtoPCancel = () => {
    setAnchorEl(true);
    setLetsPlant(false);
  };

  const handleProcessAndPay = () => {
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
      setLetsPlant(false);
      setProceedPay(true);
    }, 2000);
  };

  const HandleBackToBTH = () => {
    setAnchorEl(null);
    setLetsPlant(false);
    setProceedPay(false);
    history.push(Routes.home);
  };

  const handleClick = (event) => {
    setAnchorEl(null);
    history.push(Routes.home);
  };

  useEffect(() => {
    const isInContributionRoute = location.pathname.includes("/contribution");

    if (isInContributionRoute) {
      setAnchorEl(true);
    } else {
      setAnchorEl(false);
    }
  }, [location?.pathname]);

  const modalStyle = {
    '& .MuiPaper-root.MuiDialog-paper': {
      minWidth: { sm: "90vw", md: '78vw' },
      minHeight: '94vh',
      borderRadius: '12px',
      padding: 0,
      overflowX: 'auto'
    },
    '& .MuiDialogContent-root': {
      padding: '0 !important',
      overflowX: 'auto',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      'scrollbarWidth': 'none',
      '-ms-overflow-style': 'none'
    }
  }

  const modalStyleForest = {
    '& .MuiDialogContent-root': {
      padding: '0 !important',
      overflowX: 'auto',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      'scrollbarWidth': 'none',
      '-ms-overflow-style': 'none'
    }
  }

  const dialogComponent = (component, type) => {
    dialog.setDialog({
      ...dialog,
      open: true,
      body: component,
      container: document.querySelector('#map'),
      sx: type === 'forest' ? modalStyleForest : modalStyle
    })
  }

  const closeDialog = () => {
    dialog.setDialog({
      open: false
    })
  }

  const handleForestClick = async (data) => {
    const { forestUniqueId: uniqId } = data;
    await client.query({
      query: GET_FOREST_DETAILS_BY_ID,
      fetchPolicy: "network-only",
      variables: {
        uniqId
      }
    }).then(res => {
      const forest = res?.data?.allForests?.nodes?.[0];
      const speciesCount = res?.data?.allForests?.nodes?.[0]?.totalSpeciesPlanted;
      const forestImages = res?.data?.allForests?.nodes?.[0]?.forestAssertsByForestId?.nodes;
      const construstForestURL = (url) => url?.split(',');
      if (forest) {
        dialogComponent(
          <InfoDetails
            uniqueId={uniqId}
            title={forest?.forestName}
            logo=""
            description={forest?.forestDesc ?? ""}
            showVideo={false}
            videos={forest?.forestUrl ? construstForestURL(forest?.forestUrl) : []}
            subTitle="Stats"
            info={[
              { label: 'Oxygen Generated', value: Math.round(forest?.forestOxygen / 1000).toLocaleString(), unit: "kt" },
              { label: 'Carbon Offset', value: Math.round(forest?.forestCarbonoffset / 1000).toLocaleString(), unit: "kt" },
              { label: 'Trees Planted', value: forest?.totalTrees },
              { label: 'Species Planted', value: speciesCount ? speciesCount : 0 },
            ]}
            images={forestImages}
            width={'304px'}
            onClose={closeDialog}
            sponsoredName={forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorName}
            sponsoredLogo={forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorLogo}
            share={true}
            url={data?.nodeId ? window.location.origin + "/forest/" + forest.forestUniqueId : window.location.href}
          />, 'forest'
        )
      } else {
        dialog.setDialog({
          open: false
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const handleLocationTree = () => { }

  const handleOrderTimeline = (e, val) => {
    setTimelineOrder(val)
  }

  const handleTreeClick = async (data) => {
    let uniqId = data?.treeUniqueId;
    await client.query({
      query: GET_TREES_BY_NODEID(),
      fetchPolicy: "network-only",
      variables: {
        uniqId
      }
    }).then(res => {
      const forestTree = res?.data?.allForestTrees?.nodes?.[0];
      const forestId = res?.data?.allForestTrees?.nodes[0]?.forestByForestId?.forestUniqueId
      if (forestTree) {
        dialogComponent(
          <TreeInfo
            treeName={forestTree?.forestTreeName}
            treeUniqueId={forestTree?.treeUniqueId}
            isAlive={forestTree?.isActive}
            treeHeight={forestTree?.forestTreeHeight}
            treeDia={forestTree?.forestTreeDia}
            treeAge={forestTree?.forestTreeAge}
            treeCorban={Math.round(forestTree?.forestTreeCarbonoffset * 100) / 100}
            treeOxygen={Math.round(forestTree?.forestTreeOxygen * 100) / 100}
            treeLat={forestTree?.forestTreeGeoLat}
            treeLong={forestTree?.forestTreeGeoLong}
            isGifted={forestTree?.giftForestPlantsByGiftTreeId?.nodes.length}
            gifterName={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.name}
            gifterUrl={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.userAssetUrl}
            giftDesignation={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.designation}
            giftedOrganizationName={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.organizationName}
            giftedOrgUrl={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.orgLogoUrl}
            giftedDate={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.updatedAt}
            timelineOrder={timelineOrder}
            timelineData={forestTree?.forestPlantTimelinesByPlantId?.nodes}
            url={path.tree_id && !data?.nodeId ? window.location.href : window.location.origin + "/tree/" + forestId + "/" + forestTree.treeUniqueId}
            handleOrderTimeline={handleOrderTimeline}
            handleLocationTree={handleLocationTree}
            handleCloseTreeInfo={closeDialog}
          />, 'tree')
      } else {
        dialog.setDialog({
          open: false
        })
      }
    }).catch(err => {
      console.log(err)
      dialog.setDialog({
        open: false
      })
    })
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SEO {...seoData} />
      <div
        style={{
          width: "100%",
          height: "100%",
        }}>
        {letsPlant !== true && proceedPay !== true && (
          <CustomForestMap
            forestDetails={state?.allForestDetails}
            searchValue={props?.searchValue}
            forestList={state?.forestList}
            initialCenter={state?.initialCenter}
            handleForestClick={handleForestClick}
            handleTreeClick={handleTreeClick}
          />
        )}
        {letsPlant === true && proceedPay !== true && (
          <ContributionDetails
            handleProcessAndPay={handleProcessAndPay}
            HandlePtoPCancel={HandlePtoPCancel}
          />
        )}
        {proceedPay === true && (
          <ContributionCertificateScreen HandleBackToBTH={HandleBackToBTH} />
        )}
      </div>

      <ContributionPopUp
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px !important",
          },
          backdropFilter:
            location.pathname.includes("/contribution") === true
              ? "blur(2px)"
              : "blur(0px)",
        }}
      >
        <Contribution letsPlantFunc={letsPlantFunc} handleClick={handleClick} />
      </ContributionPopUp>
    </>
  );
};
